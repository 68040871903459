import React from 'react'

const Loading = () => {
  return (
    <div className='spiner-loader'>
      
    </div>
  )
}

export default Loading
