import React from "react";
import { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Skeleton } from "@mui/material";
import {
  useSDK,
  useContract,
  useContractWrite,
  useAddress,
  useContractRead,
} from "@thirdweb-dev/react";
import moment from "moment";
import "./page.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../components/Loading";

const PurchaseRound3 = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const address = useAddress();
  const sdk = useSDK();
  const [WithdrawTokensloading, setWithdrawTokensLoading] = useState(false);
  const [withdrawData, setWithDrawData] = useState("");
  const [tableDataLoading, setTableDataLoading] = useState(true);

  const stakeContract = "0x3CebE90b22E55863b2A9480efdD0E219788041e9";

  const { contract } = useContract(
    stakeContract
  );

  const { data: StakingCount, isLoading: isUserStakingCountLoading } = useContractRead(contract, "userStakingCount", [address]);

  // Fetch staking data only after StakingCount is available and address is set
  useEffect(() => {
    if (address && StakingCount !== undefined) {
      getData(); // Fetch data only when staking count is available
    }
  }, [address, StakingCount]);

  // Fetch stake data from contract
  const getData = async () => {
    try {
      setTableDataLoading(true);
      setLoading(true);

      const contract1 = await sdk.getContract(stakeContract);

      let details = [];

      for (let i = 0; i < StakingCount; i++) {
        const data = await contract1.call("userStaking", [address, i]);

        let amount = (data.stakedAmount / 1e6).toFixed(2);
        let duration = data.stakingDuration.toString();
        let startDate = moment.unix(data.startDate).format("DD-MM-YYYY HH:mm:ss");
        let endDate = moment.unix(data.nextClaimTime).format("DD-MM-YYYY HH:mm:ss");
        let isCompleted = data.completed.toString();
        let totalRewardClaimed = (data.claimed / 1e6).toFixed(2);
        details.push({
          amount,
          duration,
          startDate,
          endDate,
          isCompleted,
          totalRewardClaimed
        });
      }

      setData(details);
      setTableDataLoading(false);
      // console.log("Stake History Data", details);
    } catch (error) {
      setTableDataLoading(false);
      console.log("Error fetching stakes:", error);
    } finally {
      setLoading(false);
    }
  };

  // Check withdrawal eligibility
  const WithdrawCheck = async () => {
    let len = Number(StakingCount.toString());
    let promises = [];
    for (let i = 0; i < len; i++) {
      promises.push(
        sdk.getContract(stakeContract)
          .then((contract1) => contract1.call("withdrawalCompleted", [address, i]))
      );
    }
    const result = await Promise.all(promises);
    setWithDrawData(result);
  };

  // Withdraw tokens logic
  const { mutateAsync: withdraw, isLoading: isWithdrawTokensLoading } = useContractWrite(contract, "withdraw");

  const withdrawToken = async (index) => {
    setWithdrawTokensLoading(true);
    try {
      const data = await withdraw({ args: [index] });
      console.info("contract call success", data);
      toast.success("Tokens Successfully Withdrawn", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      toast.error("Token Withdrawal Failed", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("contract call failure", err);
    } finally {
      setWithdrawTokensLoading(false);
    }
  };

  // Helper to parse date from string
  function parseCustomDate(dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.split(':');
    return new Date(year, month - 1, day, hours, minutes, seconds);
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {WithdrawTokensloading && <Loading />}
      <div className="content">
        <div className="container mt-5">
          <div className="parchage_main">
            <div className="page_title">
              <p>Staking Overview</p>
            </div>
            <div className="parchage_table">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr.no</th>
                    <th className="date_table">Staking Date</th>
                    <th>Amount</th>
                    <th>Duration</th>
                    <th>Claimed Reward</th>
                    <th>Claim Date</th>
                    <th>Withdraw</th>
                  </tr>
                </thead>
                <tbody>
                  {tableDataLoading ? (
                    <TableRowsLoader rowsNum={10} />
                  ) : (
                    <>
                      {data.length > 0 ? (
                        data.map((rowData, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="date_table">{rowData.startDate}</td>
                            <td>{rowData.amount}</td>
                            <td>{rowData.duration} Days</td>
                            <td>{rowData.totalRewardClaimed}</td>
                            <td className="date_table">{rowData.endDate}</td>
                            <td>
                              {rowData.isCompleted === "true" ? (
                                <button className="button_withdrow" 
                                style={{ backgroundColor: "#f51720", color: "#fff" }}
                                disabled>
                                  End
                                </button>
                              ) : new Date() > parseCustomDate(rowData.endDate) ? (
                                <button onClick={() => withdrawToken(index)} className="button_withdrow"
                                style={{ backgroundColor: "#28a745", color: "#fff" }}
                                >
                                  Claim
                                </button>
                              ) : (
                                <button disabled className="button_withdrow">
                                  Locked
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">No Data Found</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// Loader component for table rows
const TableRowsLoader = ({ rowsNum }) => {
  return [...Array(rowsNum)].map((_, index) => (
    <TableRow key={index}>
      <TableCell component="th" scope="row">
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="text" />
      </TableCell>
    </TableRow>
  ));
};

export default PurchaseRound3;
